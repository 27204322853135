<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <!-- 搜索 -->
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <span
              >姓名：
              <el-input
                class="search_input"
                v-model="parameter.userName"
                clearable
                placeholder="请输入姓名" />
            </span>
            <span
              >部门：
              <el-select
                class="search_input"
                v-model="parameter.deptId"
                placeholder="请选择部门"
                filterable
                clearable>
                <el-option
                  v-for="item in deptList"
                  :key="item.id"
                  :label="item.deptName"
                  :value="item.id">
                </el-option>
              </el-select>
            </span>
            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)">
              搜索
            </el-button>
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <!-- 新增标签按钮-->
        <div class="fr">
          <el-button type="primary" icon="el-icon-plus" @click="add">新增</el-button>
        </div>
      </div>
      <!-- 表格展示 -->
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            height="string"
            v-loading="loading">
            <el-table-column align="center" width="50" prop="index" label="序号">
              <template slot-scope="scope">
                <span>{{ scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="userName" label="姓名" />
            <el-table-column align="center" prop="deptName" label="招聘部门" />
            <el-table-column align="center" prop="postName" label="招聘岗位" />
            <el-table-column align="center" prop="remark" label="备注" />
            <el-table-column prop="departFiles" label="简历" align="center">
              <template slot-scope="scope">
                <div>
                  <a
                    target="_blank"
                    style="text-decoration: revert; color: #409eff"
                    :href="`${filepath}${scope.row.resumePath}`">
                    {{ scope.row.resumeName }}
                  </a>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="title" fixed="right" width="70" align="center" label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
                <el-popconfirm
                  icon="el-icon-info"
                  title="确定删除该标签吗？"
                  @confirm="del(scope.row)"
                  @onConfirm="del(scope.row)">
                  <el-button class="btn_danger" size="mini" slot="reference" type="text">
                    删除
                  </el-button>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="total">
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize" />
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>

      <!-- 新增/编辑弹窗 -->
      <el-dialog append-to-body :title="title" :visible.sync="showEditDialog" width="30%">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
          <el-form-item label="姓名" prop="userName" ref="userName">
            <el-input v-model="form.userName" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="部门" prop="deptId" ref="deptId">
            <el-select v-model="form.deptId" placeholder="请选择" filterable>
              <el-option
                v-for="item in deptList"
                :key="item.id"
                :label="item.deptName"
                :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="应聘岗位" prop="postName" ref="postName">
            <el-input v-model="form.postName" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark" ref="remark">
            <el-input v-model="form.remark" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item
            style="text-align: left"
            label="简历"
            prop="resume"
            ref="resume"
            class="table">
            <UploadResume v-if="!form.resumeName" @upload="upload"></UploadResume>
            <div v-else>
              <a
                class="fj-text"
                target="_blank"
                style="text-decoration: revert; color: #409eff; padding-right: 8px"
                :href="`${filepath}${form.resumePath}`">
                {{ form.resumeName }}
              </a>
              <i @click="delResume" class="iconfont iconerror iconCost"></i>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="showEditDialog = false">取 消</el-button>
          <el-button type="primary" @click="submit" :loading="editDialogLoading">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    UploadResume: () => import('@/components/upload/UploadResume.vue'),
  },
  data() {
    return {
      parameter: {
        //获取表格
        pageNow: 1,
        pageSize: 10,
      },
      pageSize: 0, //当前页面
      total: 0, //总条数
      tableData: [], //人员列表
      loading: false,
      rules: {
        userName: [{ required: true, message: '请选择姓名', trigger: ['blur', 'change'] }],
        deptId: [{ required: true, message: '请选择部门', trigger: ['blur', 'change'] }],
        postName: [{ required: true, message: '请填写招聘岗位', trigger: ['blur', 'change'] }],
      },
      filepath: process.env.VUE_APP_FILEPATH,
      deptList: [], //部门列表
      form: {}, //弹窗表格
      title: '', //弹窗标题
      showEditDialog: false, //修改、新增弹窗
      editDialogLoading: false, //修改、新增确认按钮
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.$api.sysDept
      .listDept() //获取部门列表
      .then(res => {
        this.deptList = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.getData() //获取列表
  },
  watch: {
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
  },
  methods: {
    // 获取列表
    getData() {
      this.loading = true
      this.$api.user
        .getRecruitmentTalent(this.parameter)
        .then(res => {
          if (res.data == []) {
            this.tableData = res.data
          } else {
            this.tableData = res.data.records
            this.total = res.data.total
          }
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    // 修改
    edit(row) {
      this.form = row.deepClone()
      this.title = '编辑'
      this.showEditDialog = true
    },

    // 新增
    add() {
      this.form = { resumeName: '' }
      this.title = '新增'
      this.showEditDialog = true
    },

    // 删除
    del(row) {
      this.loading = true
      this.$api.user
        .deleteRecruitTalent(row.id)
        .then(res => {
          this.loading = false
          this.$message.success('删除成功！')
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    //清空简历
    delResume() {
      this.form.resumePath = ''
      this.form.resumeName = ''
    },

    //提交新增或编辑
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.editDialogLoading = true
          if (!this.form.resumeName) {
            this.delResume()
          }
          if (this.form.id) {
            this.$api.user
              .editRecruitTalent(this.form)
              .then(res => {
                this.editDialogLoading = false
                this.showEditDialog = false
                this.$message.success('编辑成功！')
              })
              .catch(err => {
                this.editDialogLoading = false
                console.log(err)
              })
          } else {
            this.$api.user
              .addRecruitTalent(this.form)
              .then(res => {
                this.editDialogLoading = false
                this.showEditDialog = false
                this.$message.success('新增成功！')
              })
              .catch(err => {
                this.editDialogLoading = false
                console.log(err)
              })
          }
          this.getData()
        }
      })
    },

    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter.userName = ''
      this.parameter.deptId = ''
      this.pageChangeHandler(1)
    },

    //上传简历
    upload(r) {
      this.form.resumeName = r.resumeName
      this.form.resumePath = r.resumePath
    },

    // 表格行数
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },

    // 换页
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.parameter.pageNow = 1
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/config.scss';

.mainList_operation {
  margin-top: 20px;
}

.search_input {
  width: 165px;
  margin-right: 10px;
}

.btn_danger {
  padding-left: 5px;
}

.dialog_table {
  margin: 10px 1px;
  width: 700px;
}

.mainList_operation {
  margin-top: 0px;
}

/deep/ .fullScreen.show {
  padding-top: 1000px;
}

.iconCost {
  padding-left: 10px;
  font-size: 28px;
  vertical-align: middle;
  cursor: pointer;
}
</style>
